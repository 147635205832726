<template>
  <div id="actionBar" class="flex flex-wrap justify-between  rounded-md border p-3 m-3  mt-6   bg-white">
      <div class="flex">
          <div v-for="(item, index) in actions.filter((a)=> a.position === 'start')" :key="index">
              <button v-if="item.type=='button'"  :class="item.style" class="flex items-center">
                  <i v-if="item.icon" class="material-icons mx-2">{{ item.icon }}</i>
                  {{ item.text }}
              </button>
              <router-link v-if="item.type=='link'" :to="item.to" :class="item.style" class="flex items-center">
                  <i v-if="item.icon" class="material-icons mx-2">{{ item.icon }}</i>
                  {{ item.text }}
              </router-link>
          </div>
      </div>
      <div class="flex">

      </div>
      <div class="flex">
          <div v-for="(item, index) in actions.filter((a)=> a.position === 'end')" :key="index">
              <button v-if="item.type=='button'" :class="item.style" class="flex items-center">
                  <i v-if="item.icon" class="material-icons mx-2">{{ item.icon }}</i>
                  {{ item.text }}
              </button>
              <router-link v-if="item.type=='link'" :to="item.to" :class="item.style" class="flex items-center">
                  <i v-if="item.icon" class="material-icons mr-2">{{ item.icon }}</i>
                  {{ item.text }}
              </router-link>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        actions: { type: Array, default: ()=> { return []; }}
    }
}
</script>

<style>

</style>